import { Col, Row } from "react-bootstrap";
import SubPages from "../components/SubPages";
import GayFathers from "../assets/img/schwuleVaeter.png";

export default function Gayfathers() {
  return (
    <SubPages title="Schwule Väter und Ehemänner">
      <Row className="padtopbottom2rem">
        <Col md={6}>
          <p className="padtop1rem">
            Du bist Ehemann, Vater und schwul?
            <br />
            Du liebst deine Frau, aber schaust verstohlen anderen Männern nach?
            <br />
            Du weißt nicht so richtig, was mit dir los ist?
            <br />
            Du sorgst dich um die Zukunft von dir und deiner Familie?
            <br />
            Du weißt nicht, mit wem du darüber reden kannst?
            <br />
          </p>
          <p>
            Viele entdecken sich und ihre Gefühle erst spät, haben sie jahrelang
            unterdrückt oder nur heimlich gelebt – aus Angst vor der
            Gesellschaft, den Arbeitgeber*innen, der Familie…
            <br />
            Schwul zu sein, das ist heutzutage nicht mehr wirklich
            problematisch. Das ist von vielen Teilen der Gesellschaft schon
            längst akzeptiert. <br />
            Aber dabei auch noch mit einer Frau verheiratet zu sein oder sogar
            Kinder zu haben, das ist doch schon etwas anderes.
            <br />
            Du fühlst dich allein? Das muss nicht sein. Wir sind viele, denen es
            ebenso geht.
          </p>
          <p>
            Kontakt und weitere Infos: <br />
            Telefon:{" "}
            <a href="tel:+491723280621" className="d-orange">
              0172 / 328 06 21
            </a>{" "}
            (Herbert)
            <br />
            E-Mail:{" "}
            <a
              className="d-orange"
              href="mailto:schwule.vaeter@queer-niederbayern.de"
            >
              schwule.vaeter@queer-niederbayern.de
            </a>
            . <br />
            Und wenn Du nicht direkt in die Gruppe kommen möchtest, dann können
            wir uns anfangs gerne auch außerhalb der Gruppe in einem kleineren
            Rahmen treffen.
            <br />
            Lesbische Mütter und Ehefrauen, trans*Ehepartner*innen können sich
            ebenfalls sehr gerne an uns wenden.
          </p>
        </Col>
        <Col md={6}>
          <p>
            <strong className="d-orange">
              Komm zu uns in die Gruppe „Schwule Väter“.
            </strong>
            <br />
            Die Gruppe schwuler Väter ist eine offene Gesprächs- und
            Selbsthilfegruppe. Hier geht es um die besondere Situation von
            schwulen Vätern, insbesondere in der meist schwierigen persönlichen
            Erkenntnis-, Annahme-/Akzeptanz- und Veränderungsphase, sowie deren
            Kinder aus vorherigen heterosexuellen Partnerschaften und/oder Ehen.
            Weiterhin werden Erfahrungen neuer Beziehungen mit schwulem Partner
            und dem Miteinander mit den Kindern ausgetauscht und geteilt.
            <br />
            Wir sind eine freiwillige, freie und rein ehrenamtlich geführte
            Selbsthilfegruppe, die sich mehr oder weniger regelmäßig trifft, um
            sich gegenseitig zu unterstützen und Erfahrungen auszutauschen. Das
            bedeutet, unsere Gespräche sind von „schwulem Vater zu schwulem
            Vater“. Die Gruppe wird nicht von Psycholog*innen oder
            Therapeut*innen geleitet, sondern von Betroffenen in der gleichen
            oder ähnlichen Situation.
            <br />
            Wichtig ist uns, dass keine Zwänge mit den Treffen verbunden sind –
            Du musst nicht Mitglied im Verein sein, es gibt keine
            Mitgliedslisten. Wir bilden eine offene Runde, sind aber mehr oder
            weniger regelmäßig, geplant ist einmal im Monat, unter uns. Wo
            genaue wir uns treffen, weilen wir dir auf Anfrage kurzfristig mit.
            <br />
            Die Gruppe besteht aus Teilnehmern, denen viele Situationen bekannt
            sind und in den verschiedensten Konstellationen vorhanden sind. Wir
            sind Geoutete, Ungeoutete, weiterhin Verheiratete, Geschiedene,
            Singles, Partner, usw.
            <br />
            Das hat den Vorteil, dass Du für Dich die eine oder andere Situation
            ableiten kannst, um zu sehen: wo stehst Du? Wo stehen die Anderen
            und welchen Weg kannst Du in Zukunft gehen?
            <br />
          </p>
          <img
            className="rewe"
            src={GayFathers}
            alt="Bild mit extra Mann in einer Familie"
          />
        </Col>
      </Row>
    </SubPages>
  );
}
