import SubPages from "../components/SubPages";
import EventDateAndText from "../components/calendar/EventDateAndText";

export default function Dates() {
  return (
    <SubPages title="Anstehende Termine">
      <EventDateAndText
        events={[
          {
            day: "Samstag",
            dateDate: "15. Mai",
            year: "2025",
            text: `<p><strong class="d-green">IDAHOBIT</strong> – Infostände und Aktionen in Landshut und/oder Deggendorf</p>`,
          },
          {
            day: "Samstag",
            dateDate: "07. Juni",
            year: "2025",
            text: `<p>Auch in diesem Jahr findet der <strong class="d-blue">CSD in Kelheim</strong> auf dem Ludwigsplatz vor dem Rathaus statt. Zum 6. Mal werden wir ein buntes Programm zusammenstellen und auch wieder mit einem Demonstrationszug durch die Stadt ziehen.</p>`,
          },
          {
            day: "Samstag",
            dateDate: "21. Juni",
            year: "2025",
            text: `<p><strong class="d-purple">Familienfest der KoKi Landshut</strong><br>Auch in diesem Jahr planen wir unsere Teilnahme mit einem Infostand und Angeboten für Kinder und Jugendliche beim Familienfest der koordinierenden Kinderschutzstelle Stadt Landshut auf der Wise beim Jugendkulturzentrum Alte Kaserne in Landshut.</p>`,
          },
          {
            day: "Samstag",
            dateDate: "28. Juni",
            year: "2025",
            text: `<p>Auch in diesem Jahre werden wir am <strong class="d-pink">CSD München</strong> mit einem Infostand und einer Fußgruppe im Demozug teilnehmen..</p>`,
          },
          {
            day: "Samstag",
            dateDate: "05. Juli",
            year: "2025",
            text: `<p>Zum vierten Mal werden wir am <strong class="d-orange">CSD Regensburg</strong> mit einem Infostand und einer Fußgruppe im Demozug teilnehmen.</p>`,
          },
          {
            day: "Montag",
            dateDate: "14. Juli",
            year: "2025",
            text: `<p>Immer am 14.7. wird weltweit der <strong class="d-yellow">International non-binary people’s day</strong> begangen. Er soll auf die Situation von nicht-binären Menschen aufmerksam machen und alle Menschen über die Existenz von nicht-binären Geschlechtern aufklären.Immer am 14.7. wird weltweit der International non-binary people’s day begangen. Er soll auf die Situation von nicht-binären Menschen aufmerksam machen und alle Menschen über die Existenz von nicht-binären Geschlechtern aufklären.</p>`,
          },
          {
            day: "Samstag",
            dateDate: "19. Juli",
            year: "2025",
            text: `<p>Auch in diesem Jahr findet der <strong class="d-green">CSD in Straubing</strong> auf dem Ludwigsplatz vor dem Rathaus statt. Zum 6. Mal werden wir ein buntes Programm zusammenstellen und auch wieder mit einem Demonstrationszug durch die Stadt ziehen.</p>`,
          },
          {
            day: "Samstag",
            dateDate: "09. August",
            year: "2025",
            text: `<p>Wir wollen versuchen, dass wir auch in diesem Jahr für den Demozug des <strong class="d-blue">CSD Nürnberg</strong> eine Fußgruppe zusammenstellen und teilnehmen.</p>`,
          },
          {
            day: "Donnerstag",
            dateDate: "22. August",
            year: "2025",
            text: `<p>Wir werden auch in diesem Jahr mit einer sichtbaren Fußgruppe und Vereinsfahnen am <strong class="d-purple">Dultauszug</strong> (Start am Rathaus, Marsch der Festwirte, der Honoratioren und Vereinen mit Musikkapellen zum Festplatz und Einzug ins Festzelt) teilnehmen.</p>`,
          },
          {
            day: "Montag",
            dateDate: "22. September",
            year: "2025",
            text: `<p>Start der <strong class="d-pink">Queeren Woche in Landshut</strong></p>`,
          },
          {
            day: "Samstag",
            dateDate: "27. September",
            year: "2025",
            text: `<p>Wieder auf dem Ländtorplatz werden wir bereits zum <strong class="d-orange">7. Mal einen Christopher-Street-Day in Landshut</strong> durchführen. Es wird wieder ein buntes und auch politisches Bühnenprogramm und viele Infostände geben. Und wir werden wieder mit einem Demonstrationszug durch die Landshuter Innenstadt ziehen.</p>`,
          },
          {
            day: "Samstag",
            dateDate: "11. Oktober",
            year: "2025",
            text: `<p>Am <strong class="d-yellow">Coming Out Day</strong> soll Menschen Mut gemacht werden, sich outen zu können. Außerdem soll die Mehrheitsgesellschaft darauf aufmerksam gemacht werden, wie schwierig es auch heute noch ist, sich zu outen.Wir werden wieder mit Infoständen und Aktionen in Landshut und/oder anderswo in Niederbayern sichtbar sein.</p>`,
          },
          {
            day: "Donnerstag",
            dateDate: "20. November",
            year: "2025",
            text: `<p>Am <strong class="d-green">Transgender Day of Remembrance</strong> (auf Deutsch: Tag der Erinnerung an die Opfer von Transfeindlichkeit) wird seit 1998 jedes Jahr am 20.11. begangen. Dabei wird der Opfer von Transfeindlichkeit, und insbesondere ermordeten trans*-Personen gedacht.</p>`,
          },
          {
            day: "Montag",
            dateDate: "01. Dezember",
            year: "2025",
            text: `<p>Der <strong class="d-blue">Welt-AIDS-Tag</strong> findet jedes Jahr am 1. Dezember statt. Er will auf die Situation von Menschen, die HIV-positiv sind bzw. AIDS haben, aufmerksam machen, Diskriminierungen abbauen und Solidarität fördern. Er erinnert an die Menschen, die an AIDS gestorben sind und klärt über HIV auf, z.B. wie x sich gegen HIV schützen kann.</p>`,
          },
        ]}
      ></EventDateAndText>
    </SubPages>
  );
}
