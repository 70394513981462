import CSDDates from "../components/CSDDates";
import SubPages from "../components/SubPages";
import RowColFull from "../components/UI/RowColFull";

export default function CSD() {
  return (
    <SubPages title="Christopher-Street-Days">
      <RowColFull>
        <p>
          Der Christopher Street Day (CSD) ist ein Fest-, Gedenk- und
          Demonstrationstag von Lesben, Schwulen, Bisexuellen, trans* und
          inter-Personen und allen queeren Menschen. Er erinnert an den Aufstand
          von Homosexuellen und anderen sexuellen Identitäten gegen
          Polizeigewalt und Repressionen am 28. Juni 1969 in der Bar „Stonewall
          Inn“ in der New Yorker Christopher-Street. Heute demonstrieren wir mit
          den CSDs gegen Ausgrenzung und Diskriminierung sowie für Toleranz und
          Akzeptanz. Deshalb ist der CSD jährlich eines unserer wichtigsten und
          größten Projekte.
        </p>
        <p>
          Unser erster CSD Niederbayern am 26. Sept. 2019 in Landshut war ein
          voller Erfolg und übertraf mit rund 2.500 Demonstrierenden und rund
          5.000 Feiernden beim bunten Straßenfest nicht nur unsere Erwartungen,
          sondern auch die der Stadt Landshut!
        </p>
        <p>
          Mit den niederbayerischen CSDs wollen wir die queere Community und
          viele andere Menschen erreichen. Wir bieten dabei viele verschiedene
          Programmpunkte und rundum ein buntes Angebot. Mit einer großen
          Demoparade ziehen wir durch die Innenstadt und demonstrieren für
          unsere Forderungen. Auf den bunten CSD-Straßenfesten wird an vielen
          Infoständen und mit einem vielseitigen Bühnenprogramm Raum für
          Austausch & Information, Vernetzung und Diskussion geboten. Soweit
          örtlich möglich wollen wir den CSD-Tag mit einer After-CSD-Party
          abschließen.
        </p>
        <p>
          Du hast Lust den CSD aktiv mitzugestalten? Dann werde Teil unserer AG
          CSD. Alle Informationen und Kontakte zur Arbeitsgruppe CSD findest du{" "}
          <a className="d-orange" href="./work-groups">
            hier
          </a>
          .
        </p>
      </RowColFull>
      <hr />
      <CSDDates color="d-orange" withDonation={true} />
      <hr />
      <RowColFull className="padtop2rem">
        <h2 className="d-orange center">
          Warum wir auch heute noch Christopher-Street-Days brauchen
        </h2>
      </RowColFull>
      <RowColFull>
        <p>
          In den USA werden seit einigen Jahren in vielen Bundesstaaten queere
          Rechte beschnitten bzw. abgeschafft; so z. B. in Florida unter dem
          rechtskonservativen Gouverneur Ron DeSantis und in anderen
          republikanisch regierten Bundesstaaten. Es werden z. B. Aufklärung
          über LGBTIQ* in Schulen untersagt oder transfeindliche Gesetze neu
          beschlossen.
        </p>
        <p>
          Oder im EU-Land Italien unter der rechtspopulistisch-neofaschistischen
          Regierung unter Ministerpräsidentin Georgia Meloni. Hier wurde u. a.
          das Namens- und Adoptionsrecht für Kinder in gleichgeschlechtlichen
          Partnerschaften sogar rückwirkend bis 2017 abgeschafft. Auch die
          Möglichkeit der „Lebenspartnerschaft“ (in Italien gibt es bis heute
          keine Ehe für Alle) soll zurückgenommen werden. Mehr über die
          LSBTIQ*-feindlichen Machenschaften der italienischen Regierung unter
          <a
            href="https://www.zdf.de/nachrichten/politik/ausland/italien-lgbtqia-meloni-100.html"
            className="d-orange"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            diesem Link
          </a>
          .
        </p>
        <p>
          In Polen haben sich seit 2019 sehr viele Städte, Gemeinden und
          Provinzen als LSBT-ideologiefreie Zonen erklärt, um queere Menschen zu
          stigmatisieren und auszuschließen.
        </p>
        <p>
          Das EU-Land Ungarn unter dem rechtskonservativen Ministerpräsidenten
          Viktor Orban und seiner regierenden Fidesz-Partei diskriminiert die
          LGBTIQ*-Community seit Jahren: So dürfen Lehrerinnen und Lehrer nicht
          über Homosexualität unterrichten, der Verkauf von Büchern, die
          LGBTIQ*-Themen behandeln, ist eingeschränkt und in Reden von
          Fidesz-Politikern wird die LGBTIQ*-Community offen diskriminiert.
          Unter anderem wird dort trans* sein mit Pädophilie gleichgestellt.
          Seit 2020 stehen nun in der ungarischen Verfassung homophobe Passagen.
          Die Europäische Kommission verklagt Ungarn daher vor dem Gerichtshof
          der Europäischen Union.
          <br /> Weiter Informationen zur LGBTIQ*-feindlichen Politik in Ungarn
          findet ihr auf{" "}
          <a
            href="https://www.freiheit.org/de/mitteleuropa-und-baltische-staaten/diskriminierung-zum-machterhalt-orban-hat-die-lgbt-community-zum"
            className="d-orange"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            freiheit.org
          </a>
          .
        </p>
        <p>
          Im August 2024 ist nach Ungarn und Georgien auch im EU-Land Bulgarien
          ein Anti-LSBTIQ*-Gesetz in Kraft getreten. Dadurch sollen
          Bildungsprogramme über „nicht-traditionelle sexuelle Orientierungen
          und Bestimmung der Geschlechtsidentität“ an Schulen verboten werden.
          Aufklärung wird damit fortan als Propaganda gehandhabt, also
          kriminalisiert und ist nun unter Strafe gestellt. Bulgarien stellt
          sich damit klar in eine Reihe mit anderen, pro-russischen Staaten und
          setzt damit nicht nur ein Zeichen gegen die Vielfaltsgesellschaft,
          sondern auch gegen die EU.
          <br /> Mehr zu Bulgarien bei{" "}
          <a
            href="https://www.freiheit.org/de/deutschland/russlands-feldzug-gegen-lgbtiq-europa-geht-weiter"
            className="d-orange"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            freiheit.org
          </a>{" "}
          oder beim{" "}
          <a
            href="https://www.lsvd.de/de/ct/12561-Bulgarien-kriminalisiert-LSBTIQ-Schulaufklaerung"
            className="d-orange"
            target="_blank"
            rel="noreferrer"
          >
            lsvd
          </a>
          .
        </p>
        <p>
          Weltweit wird in <strong>63 Staaten</strong> Homosexualität{" "}
          <strong>strafrechtlich verfolgt</strong>, in
          <strong>12 Ländern</strong> droht sogar die{" "}
          <strong>Todesstrafe</strong> für Lesben und Schwule. Manche davon
          setzen die Todesstrafe auch teilweise um: Iran, Nigeria,
          Saudi-Arabien, Somalia, Jemen. Eine rechtliche Möglichkeit ist die
          Todesstrafe auch in Afghanistan, Brunei, Mauritius, Katar, Pakistan,
          Uganda und in den Vereinigten Arabischen Emiraten. Vielerorts sind
          staatliche Behörden an der Unterdrückung von LGBTIQ* beteiligt,
          verweigern ihnen jeglichen Schutz vor Anfeindungen und Gewalt.
          <br /> In vielen Fällen schüren religiöse und politische Führer ein
          Klima des Hasses. LGBTIQ* sollen eingeschüchtert und in die
          Unsichtbarkeit gedrängt werden. Verfolgung und Ausgrenzung, oft auch
          durch die eigene Familie, führt häufig zu bitterer Armut und einem
          Leben <strong>am Rand der Gesellschaft</strong>. LGBTIQ*-feindliche
          Gewalt bleibt vielerorts ohne Konsequenzen für die Täter. Polizei und
          andere Staatsorgane verweigern oftmals jede Hilfe oder sind selbst an
          der Hetze, Erpressung und Gewalt beteiligt. 14 Staaten kriminalisieren
          trans* Personen. <br />
          Weiter Informationen zur weltweiten Situation von LSBTIQ*-Menschen
          findet ihr beim{" "}
          <a
            href="https://www.lsvd.de/de/ct/1245-LGBT-Rechte-weltweit"
            className="d-orange"
            target="_blank"
            rel="noreferrer"
          >
            lsvd
          </a>
          .
        </p>
        <p>
          In Bayern hat zwar Ministerpräsident Dr. Markus Söder im Frühjahr vor
          der Landtagswahl einen Queeren Aktionsplan Bayern angekündigt.
          Allerdings verhindern die Freien Wähler und insbesondere deren
          Vorsitzender und stellvertretender Ministerpräsident Hubert Aiwanger
          einen solchen Queeren Aktionsplan Bayern. So haben die Freien Wähler
          und Hubert Aiwanger mehrfach verhindert, dass der Queere Aktionsplan
          Bayern auf die Tagesordnung des Kabinetts kommt. Und den Versuch, den
          Queeren Aktionsplan Bayern in den Koalitionsvertrag zu schreiben, hat
          wiederum Hubert Aiwanger persönlich verhindert.
        </p>
        <p>
          Und ganz aktuell im Bundestagswahlkampf 2025 wollen CDU, CSU, BSW,
          Freie Wähler und AfD das erst zum 1. November 2024 in Kraft getretene
          Selbstbestimmungsgesetz wieder rückgängig machen bzw. Abschaffen.
          Diese Parteien wollen als zurück zur mehrfach verfassungswidrigen
          Gesetzgebung des alten Transsexuellengesetzes (TSG). Sie wollen damit
          auch das Grundgesetz §1 – „Die würde des Menschen ist unantastbar“
          absichtlich missachten.Die vorgenannten Parteien wollen teilweise auch
          die Ehe für gleichgeschlechtliche Paare wieder abschaffen.
        </p>
        <p>
          Alle diese Beispiele zeigen, dass es noch immer und noch lange wichtig
          ist, Christopher-Street-Days abzuhalten, um weiter für queere Rechte
          und Gleichstellung zu kämpfen.
        </p>
      </RowColFull>
    </SubPages>
  );
}
