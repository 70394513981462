import MainPages from "../components/MainPages";
import RowColFull from "../components/UI/RowColFull";

export default function Activities() {
  return (
    <MainPages
      topImage="meeting-img"
      bottomImage="meeting-img2"
      title="Aktivitäten"
    >
      <RowColFull>
        <p>
          Queer in Niederbayern e. V. ist ausschließlich ehrenamtlich
          organisiert und versteht sich als Plattform zur Umsetzung queerer
          Ideen. Unsere{" "}
          <a className="d-orange" href="youthgroups">
            Jugendgruppen
          </a>
          ,{" "}
          <a className="d-orange" href="regulars-table">
            Stammtische
          </a>{" "}
          sowie unsere{" "}
          <a className="d-orange" href="work-groups">
            Arbeitsgruppen
          </a>{" "}
          freuen sich jederzeit über Leute, die Lust haben eigene Ideen
          einzubringen oder bei QUEERen Projekten mitzumachen.
        </p>

        <p>
          Seit unserer Gründung haben sich so eine Fülle von{" "}
          <strong className="d-orange">Aktivitäten</strong> ergeben, die von
          kulturellen Veranstaltungen (Lesungen, Podiumsdiskussionen,
          Filmreihen, etc.), über Gruppenangebote (queere Jugendgruppen,
          Trans*-Gruppe, Elternstammtisch, Gruppe schwule Väter und Ehemänner,
          etc.) und politische Aktionen (Christopher Street Day, IDAHOBIT am 17.
          Mai, Coming-out-Day am 11. Oktober, Podiumsdiskussionen etc.) bis hin
          zu Aufklärungsprojekten (Schulprojekt etc.) und unsere durch das
          Staatsministerium für Soziales geförderten{" "}
          <a href="guidance" className="d-orange">
            queeren Beratungsstelle up2you
          </a>{" "}
          reichen.
        </p>

        <p>
          Bei vielen unserer Projekte steht der Austausch zwischen Community und
          Öffentlichkeit im Fokus, denn fehlende Begegnung erzeugt Fremdheit.
          Und diese ist der Boden für Ängste, Ressentiments und Diskriminierung.
          Wir wollen den Austausch, die Konfrontation, die Diskussion und
          gemeinsames Engagement!
        </p>

        <p>
          Wir sind die größte und niederbayernweit aktive queeren
          Organisationen.
        </p>
      </RowColFull>
    </MainPages>
  );
}
