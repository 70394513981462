import RowColFull from "../components/UI/RowColFull";

export default function CSDDates(props) {
  return (
    <>
      <RowColFull className="padtop2rem">
        <h2 className={`${props.color} center`}>
          Safe the date: CSD-Termine 2025
        </h2>
      </RowColFull>
      <RowColFull className="padtopbottom2rem">
        <p className="center">
          6. CSD Kelheim: 7. Juni 2025
          <br />
          6. CSD Straubing: 19. Juli 2025
          <br />
          7. CSD Landshut: Samstag, 27. Sept. 2025
        </p>

        {props.withDonation && (
          <p className="center">
            <strong>
              CSDs sind für uns finanziell auch immer anspruchsvoll. Allein eine
              Bühne mit Ton- und Lichttechnik verursachen Kosten von rund 5.000
              Euro. <br />
              Du möchtest uns unterstützen? Sehr kannst du uns deine Spende
              gerne per Überweisung zukommen lassen.
              <br />
              <br />
              <span className={`${props.color}`}>
                Queer in Ndb. e. V.
                <br />
                IBAN: DE09 7435 0000 0020 9682 56
                <br />
                Sparkasse Landshut
                <br />
                Verwendungszweck: Spende
              </span>
            </strong>
          </p>
        )}
      </RowColFull>
    </>
  );
}
